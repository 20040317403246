import { defineStore } from "pinia";
import { z } from "zod";

export const useCourseReferenceStore = defineStore( 'referenceStore', () => {
    const app = useNuxtApp();

    //Props
    const courseReferences = ref(new Array<ICourseClassificationQuery>());
    const venueReferences = ref(new Array<IVenueTypesQuery>());
    const mediaReferences = ref(new Array<IMediaTypesQuery>()); 
    const eventsConferencesFilterReferences = ref(new Array<IEventsCoursesTypesQuery>());
    const facultyDivisionFilterReferences = ref(new Array<IFacultyDivisionQuery>());
    const courseClassificationLookup = ref(new Array<ICourseClassification>());
    const venueLookup = ref(new Array<IVenueType>());
    const mediaLookup = ref(new Array<IMediaType>());
    const interestAreaReferences = ref(new Array<IInterestAreaQuery>());
    const interestAreaLookup = ref(new Array<IInterestArea>());
    const contentHubInterestAreaReferences = ref(new Array<IContentHubInterestAreaQuery>()); 
    const contentHubInterestAreaLookup = ref(new Array<IContentHubInterestArea>());
    const courseInterestAreaReferences = ref(new Array<ICourseInterestAreaQuery>()); 
    const courseInterestAreaLookup = ref(new Array<ICourseInterestArea>());
    const newsInterestAreaLookup = ref(new Array<ICourseInterestArea>());
    const eventsCourseTypeLookup = ref(new Array<IEventsCoursesType>());
    const facultyDivisionLookup = ref(new Array<IFacultyDivision>());
    const applicationStepLookup = ref(new Array<IProgrammeApplicationStep>());
    const applicationStepDirectionColourLookup = ref(new Array<IProgrammeApplicationDirection>());
    const courseFinderCostReferences = ref(new Array<ICourseFinderCostQuery>()); 
    const courseFinderCostLookup = ref(new Array<ICourseFinderCost>());
    const courseFinderDurationReferences = ref(new Array<ICourseFinderDurationQuery>()); 
    const courseFinderDurationLookup = ref(new Array<ICourseFinderDuration>());
    var eventsArr = ref(new Array<String>());
    var programmeCourseArr = ref(new Array<String>());

    //Getters
    const getCourseReferences = computed(
        () => {
            return courseReferences.value;
        }
    );

    //Actions
    async function fetchAllReferences() {
        await Promise.all([
            courseReferences.value.length === 0 ? fetchCourseReferences() : Promise.resolve(),
            interestAreaReferences.value.length === 0 ? fetchInterestAreaReferences() : Promise.resolve(),
            venueReferences.value.length === 0 ? fetchVenueReferences() : Promise.resolve(),
            eventsConferencesFilterReferences.value.length === 0 ? fetchEventsCourseReferences() : Promise.resolve()
        ]);
    }

    async function fetchCourseReferences() {
        let courseReferenceData = [] as z.infer<typeof CourseClassificationQuery>
        await fetchJsonData('reference/courseclassifications')
            .then(courseClassificationQueryJson => { courseReferenceData = parseJSON(CourseClassificationQuery, courseClassificationQueryJson) })
            .catch(error => {
                // Log error to Azure Log Analytics or Alert GIBS
                console.error(JSON.stringify(error, null, 2))
                courseReferenceData = []
            });
        courseReferences.value = courseReferenceData;

        mapCourseClassificationLookup();
    }

    async function fetchInterestAreaReferences() {
        let interestAreaReferenceData = [] as z.infer<typeof InterestAreaQuery>
        await fetchJsonData('reference/interestareas')
            .then(interestAreaQueryJson => { interestAreaReferenceData = parseJSON(InterestAreaQuery, interestAreaQueryJson) })
            .catch(error => {
                // Log error to Azure Log Analytics or Alert GIBS
                console.error(JSON.stringify(error, null, 2))
                interestAreaReferenceData = []
            });
        interestAreaReferences.value = interestAreaReferenceData; 
        interestAreaLookup.value = interestAreaReferences.value.map((interestAreaQuery) => { 
            let interestAreaType: IInterestArea = {
                id: interestAreaQuery.Id,
                text: '',
                background: '',
                border: '',
                icon: '',
                name: ''
            };

            interestAreaType.id = interestAreaQuery.Id;
            interestAreaType.name = interestAreaQuery.InterestAreaName; 

            if (interestAreaQuery.Id === app.$EnumInterestArea.CoachingFacilitationMentorship) {
                interestAreaType.text = 'text-pucered';
                interestAreaType.background = 'bg-pucered';
                interestAreaType.border = 'border-l-pucered';
                interestAreaType.icon = 'interest-area/gibs-ia-coaching-facilitation-mentorship';
            }
            else if (interestAreaQuery.Id === app.$EnumInterestArea.DigitisationInnovation) {
                interestAreaType.text = 'text-skobeloff';
                interestAreaType.background = 'bg-skobeloff';
                interestAreaType.border = 'border-l-skobeloff';
                interestAreaType.icon = 'interest-area/gibs-ia-digitisation-innovation';
            }
            else if (interestAreaQuery.Id === app.$EnumInterestArea.Entrepreneurship) {
                interestAreaType.text = 'text-ruddybrown';
                interestAreaType.background = 'bg-ruddybrown';
                interestAreaType.border = 'border-l-ruddybrown';
                interestAreaType.icon = 'interest-area/gibs-ia-entrepreneuship';
            }
            else if (interestAreaQuery.Id === app.$EnumInterestArea.ExecutiveDevelopment) {
                interestAreaType.text = 'text-steelteal';
                interestAreaType.background = 'bg-steelteal';
                interestAreaType.border = 'border-l-steelteal';
                interestAreaType.icon = 'interest-area/gibs-ia-executive-development';
            }
            else if (interestAreaQuery.Id === app.$EnumInterestArea.Finance) {
                interestAreaType.text = 'text-mughalgreen';
                interestAreaType.background = 'bg-mughalgreen';
                interestAreaType.border = 'border-l-mughalgreen';
                interestAreaType.icon = 'interest-area/gibs-ia-finance';
            }
            else if (interestAreaQuery.Id === app.$EnumInterestArea.ManagementLeadershipExcellence) {
                interestAreaType.text = 'text-androidgreen';
                interestAreaType.background = 'bg-androidgreen';
                interestAreaType.border = 'border-l-androidgreen';
                interestAreaType.icon = 'interest-area/gibs-ia-management-leadership-excellence';
            }
            else if (interestAreaQuery.Id === app.$EnumInterestArea.MarketingSales) {
                interestAreaType.text = 'text-marigold';
                interestAreaType.background = 'bg-marigold';
                interestAreaType.border = 'border-l-marigold';
                interestAreaType.icon = 'interest-area/gibs-ia-marketing-sales';
            }
            else if (interestAreaQuery.Id === app.$EnumInterestArea.General) {
                interestAreaType.text = 'text-catalinablue';
                interestAreaType.background = 'bg-catalinablue';
                interestAreaType.border = 'border-l-catalinablue';
                interestAreaType.icon = 'interest-area/gibs-ia-other';
            }
            else if (interestAreaQuery.Id === app.$EnumInterestArea.PersonalOrganisationalEffectiveness) {
                interestAreaType.text = 'text-honeyflower';
                interestAreaType.background = 'bg-honeyflower';
                interestAreaType.border = 'border-l-honeyflower';
                interestAreaType.icon = 'interest-area/gibs-ia-personal-development';
            }
            else if (interestAreaQuery.Id === app.$EnumInterestArea.Strategy) {
                interestAreaType.text = 'text-rubyred';
                interestAreaType.background = 'bg-rubyred';
                interestAreaType.border = 'border-l-rubyred';
                interestAreaType.icon = 'interest-area/gibs-ia-strategy';
            }
            return interestAreaType;
        });
        return interestAreaLookup.value;
    }

    async function fetchCourseInterestAreaReferences() {
        let interestAreaReferenceData = [] as z.infer<typeof CourseInterestAreaQuery>
        await fetchJsonData('reference/crm-interestareas')
            .then(interestAreaQueryJson => { interestAreaReferenceData = parseJSON(CourseInterestAreaQuery, interestAreaQueryJson) })
            .catch(error => {
                // Log error to Azure Log Analytics or Alert GIBS
                console.error(JSON.stringify(error, null, 2));
                interestAreaReferenceData = [];
            });
        courseInterestAreaReferences.value = interestAreaReferenceData; 
        courseInterestAreaLookup.value = courseInterestAreaReferences.value.map((interestAreaQuery) => { 
            let interestAreaType: ICourseInterestArea = {
                id: interestAreaQuery.Id,
                text: '',
                background: '',
                border: '',
                icon: '',
                name: interestAreaQuery.Name
            };

            if (interestAreaQuery.Id === app.$EnumCourseInterestArea.CoachingFacilitationMentorship) {
                interestAreaType.text = 'text-pucered';
                interestAreaType.background = 'bg-pucered';
                interestAreaType.border = 'border-l-pucered';
                interestAreaType.icon = 'interest-area/gibs-ia-coaching-facilitation-mentorship';
            }
            else if (interestAreaQuery.Id === app.$EnumCourseInterestArea.DigitisationInnovation) {
                interestAreaType.text = 'text-skobeloff';
                interestAreaType.background = 'bg-skobeloff';
                interestAreaType.border = 'border-l-skobeloff';
                interestAreaType.icon = 'interest-area/gibs-ia-digitisation-innovation';
            }
            else if (interestAreaQuery.Id === app.$EnumCourseInterestArea.Entrepreneurship) {
                interestAreaType.text = 'text-ruddybrown';
                interestAreaType.background = 'bg-ruddybrown';
                interestAreaType.border = 'border-l-ruddybrown';
                interestAreaType.icon = 'interest-area/gibs-ia-entrepreneuship';
            }
            else if (interestAreaQuery.Id === app.$EnumCourseInterestArea.Finance) {
                interestAreaType.text = 'text-mughalgreen';
                interestAreaType.background = 'bg-mughalgreen';
                interestAreaType.border = 'border-l-mughalgreen';
                interestAreaType.icon = 'interest-area/gibs-ia-finance';
            }
            else if (interestAreaQuery.Id === app.$EnumCourseInterestArea.ManagementLeadershipExcellence) {
                interestAreaType.text = 'text-androidgreen';
                interestAreaType.background = 'bg-androidgreen';
                interestAreaType.border = 'border-l-androidgreen';
                interestAreaType.icon = 'interest-area/gibs-ia-management-leadership-excellence';
            }
            else if (interestAreaQuery.Id === app.$EnumCourseInterestArea.MarketingSales) {
                interestAreaType.text = 'text-marigold';
                interestAreaType.background = 'bg-marigold';
                interestAreaType.border = 'border-l-marigold';
                interestAreaType.icon = 'interest-area/gibs-ia-marketing-sales';
            }
            else if (interestAreaQuery.Id === app.$EnumCourseInterestArea.Strategy) {
                interestAreaType.text = 'text-rubyred';
                interestAreaType.background = 'bg-rubyred';
                interestAreaType.border = 'border-l-rubyred';
                interestAreaType.icon = 'interest-area/gibs-ia-strategy';
            }
            else if (interestAreaQuery.Id === app.$EnumCourseInterestArea.ExecutiveDevelopment) {
                interestAreaType.text = 'text-steelteal';
                interestAreaType.background = 'bg-steelteal';
                interestAreaType.border = 'border-l-steelteal';
                interestAreaType.icon = 'interest-area/gibs-ia-executive-development';
            }
            else if (interestAreaQuery.Id === app.$EnumCourseInterestArea.PersonalAndOrganisationalEffectiveness) {
                interestAreaType.text = 'text-honeyflower';
                interestAreaType.background = 'bg-honeyflower';
                interestAreaType.border = 'border-l-honeyflower';
                interestAreaType.icon = 'interest-area/gibs-ia-personal-development';
            }
            else if (interestAreaQuery.Id === app.$EnumCourseInterestArea.General) {
                interestAreaType.text = 'text-catalinablue';
                interestAreaType.background = 'bg-catalinablue';
                interestAreaType.border = 'border-l-catalinablue';
                interestAreaType.icon = 'interest-area/gibs-ia-other';
            }

            return interestAreaType;
        });
        return courseInterestAreaLookup.value;
    }  

    async function fetchCourseDurationReferences() { 
        let durationReferenceData = [] as z.infer<typeof CourseFinderDuration>
        await fetchJsonData('reference/course-finder-duration')
            .then(durationQueryJson => { durationReferenceData = parseJSON(CourseFinderDuration, durationQueryJson) })
            .catch(error => {
                // Log error to Azure Log Analytics or Alert GIBS
                console.error(JSON.stringify(error, null, 2));
                durationReferenceData = [];
            });
            courseFinderDurationReferences.value = durationReferenceData; 
            courseFinderDurationLookup.value = courseFinderDurationReferences.value.map((durationQuery) => { 
            let durationType: ICourseFinderDuration = {
                id: durationQuery.Id,
                text: '',
                background: '',
                border: '',
                icon: '',
                name: durationQuery.Name
            };

            if (durationQuery.Id === app.$EnumCourseFinderDurationFilterType.Short) { 
            }
            else if (durationQuery.Id === app.$EnumCourseFinderDurationFilterType.Medium) { 
            }
            else if (durationQuery.Id === app.$EnumCourseFinderDurationFilterType.Long) { 
            } 
            return durationType;
        });
        return courseFinderDurationLookup.value;
    }  

    async function fetchCourseFinderCostReferences() { 
        let costReferenceData = [] as z.infer<typeof CourseFinderCost>
        await fetchJsonData('reference/course-finder-cost')
            .then(costQueryJson => { costReferenceData = parseJSON(CourseFinderCost, costQueryJson) })
            .catch(error => {
                // Log error to Azure Log Analytics or Alert GIBS
                console.error(JSON.stringify(error, null, 2));
                costReferenceData = [];
            });
            courseFinderCostReferences.value = costReferenceData; 
            courseFinderCostLookup.value = courseFinderCostReferences.value.map((costQuery) => { 
            let costType: ICourseFinderCost = {
                id: costQuery.Id,
                text: '',
                background: '',
                border: '',
                icon: '',
                name: costQuery.Name
            };

            if (costQuery.Id === app.$EnumCourseFinderCostFilterType.Under5k) { 
            }
            else if (costQuery.Id === app.$EnumCourseFinderCostFilterType.From5to25k) { 
            }
            else if (costQuery.Id === app.$EnumCourseFinderCostFilterType.From25to80k) { 
            }
            else if (costQuery.Id === app.$EnumCourseFinderCostFilterType.Over80k) { 
            } 
            return costType;
        });
        return courseFinderCostLookup.value;
    }  

    async function fetchContentHubInterestAreaReferences() {
        let interestAreaReferenceData = [] as z.infer<typeof NewsInterestAreaQuery>
        await fetchJsonData('reference/interestareas')
            .then(interestAreaQueryJson => { interestAreaReferenceData = parseJSON(NewsInterestAreaQuery, interestAreaQueryJson) })
            .catch(error => {
                // Log error to Azure Log Analytics or Alert GIBS
                console.error(JSON.stringify(error, null, 2));
                interestAreaReferenceData = [];
            });
        contentHubInterestAreaReferences.value = interestAreaReferenceData; 
        contentHubInterestAreaLookup.value = contentHubInterestAreaReferences.value.map((interestAreaQuery) => { 
            let interestAreaType: ICourseInterestArea = {
                id: interestAreaQuery.Id,
                text: '',
                background: '',
                border: '',
                icon: '',
                name: interestAreaQuery.InterestAreaName
            };
 
            if (interestAreaQuery.Id === app.$EnumInterestArea.CoachingFacilitationMentorship) {
                interestAreaType.text = 'text-pucered';
                interestAreaType.background = 'bg-pucered';
                interestAreaType.border = 'border-l-pucered';
                interestAreaType.icon = 'interest-area/gibs-ia-coaching-facilitation-mentorship';
            }
            else if (interestAreaQuery.Id === app.$EnumInterestArea.DigitisationInnovation) {
                interestAreaType.text = 'text-skobeloff';
                interestAreaType.background = 'bg-skobeloff';
                interestAreaType.border = 'border-l-skobeloff';
                interestAreaType.icon = 'interest-area/gibs-ia-digitisation-innovation';
            }
            else if (interestAreaQuery.Id === app.$EnumInterestArea.Entrepreneurship) {
                interestAreaType.text = 'text-ruddybrown';
                interestAreaType.background = 'bg-ruddybrown';
                interestAreaType.border = 'border-l-ruddybrown';
                interestAreaType.icon = 'interest-area/gibs-ia-entrepreneuship';
            }
            else if (interestAreaQuery.Id === app.$EnumInterestArea.Finance) {
                interestAreaType.text = 'text-mughalgreen';
                interestAreaType.background = 'bg-mughalgreen';
                interestAreaType.border = 'border-l-mughalgreen';
                interestAreaType.icon = 'interest-area/gibs-ia-finance';
            }
            else if (interestAreaQuery.Id === app.$EnumInterestArea.ManagementLeadershipExcellence) {
                interestAreaType.text = 'text-androidgreen';
                interestAreaType.background = 'bg-androidgreen';
                interestAreaType.border = 'border-l-androidgreen';
                interestAreaType.icon = 'interest-area/gibs-ia-management-leadership-excellence';
            }
            else if (interestAreaQuery.Id === app.$EnumInterestArea.MarketingSales) {
                interestAreaType.text = 'text-marigold';
                interestAreaType.background = 'bg-marigold';
                interestAreaType.border = 'border-l-marigold';
                interestAreaType.icon = 'interest-area/gibs-ia-marketing-sales';
            }
            else if (interestAreaQuery.Id === app.$EnumInterestArea.ExecutiveDevelopment) {
                interestAreaType.text = 'text-steelteal';
                interestAreaType.background = 'bg-steelteal';
                interestAreaType.border = 'border-l-steelteal';
                interestAreaType.icon = 'interest-area/gibs-ia-executive-development';
            }
            else if (interestAreaQuery.Id === app.$EnumInterestArea.General) {
                interestAreaType.text = 'text-catalinablue';
                interestAreaType.background = 'bg-catalinablue';
                interestAreaType.border = 'border-l-catalinablue';
                interestAreaType.icon = 'interest-area/gibs-ia-other';
            }
            else if (interestAreaQuery.Id === app.$EnumInterestArea.PersonalOrganisationalEffectiveness) {
                interestAreaType.text = 'text-honeyflower';
                interestAreaType.background = 'bg-honeyflower';
                interestAreaType.border = 'border-l-honeyflower';
                interestAreaType.icon = 'interest-area/gibs-ia-personal-development';
            }
            else if (interestAreaQuery.Id === app.$EnumInterestArea.Strategy) {
                interestAreaType.text = 'text-rubyred';
                interestAreaType.background = 'bg-rubyred';
                interestAreaType.border = 'border-l-rubyred';
                interestAreaType.icon = 'interest-area/gibs-ia-strategy';
            }
            return interestAreaType;
        });
        return contentHubInterestAreaLookup.value;
    } 

    async function fetchVenueReferences() {
        let venueTypesData = [] as z.infer<typeof VenueTypesQuery>;
        await fetchJsonData('reference/venue')
            .then(venueTypesQueryJson => { venueTypesData = parseJSON(VenueTypesQuery, venueTypesQueryJson) })
            .catch(error => {
                // Log error to Azure Log Analytics or Alert GIBS
                console.error(JSON.stringify(error, null, 2))
                venueTypesData = []
            });
        venueReferences.value = venueTypesData;
        //map venue lookup
        venueLookup.value = venueReferences.value.map((venueTypeQuery) => {
            let venueType: IVenueType = {
                id: -1,
                text: '',
                icon: ''
            };

            venueType.id = venueTypeQuery.Id;
            venueType.text = venueTypeQuery.VenueName;

            if (venueTypeQuery.Id === app.$EnumVenueType.Campus) {
                venueType.icon = 'venue/gibs-venue-campus';
            }
            else if (venueTypeQuery.Id === app.$EnumVenueType.Hybrid) {
                venueType.icon = 'venue/gibs-venue-hybrid';
            }
            else if (venueTypeQuery.Id === app.$EnumVenueType.Online) {
                venueType.icon = 'venue/gibs-venue-online';
            }
            return venueType;
        });
        return venueLookup.value;
    }

    async function fetchMediaReferences() {
        let mediaTypesData = [] as z.infer<typeof MediaTypesQuery>
        await fetchJsonData('reference/newsmediatypes')
            .then(mediaTypesQueryJson => { mediaTypesData = parseJSON(MediaTypesQuery, mediaTypesQueryJson) })
            .catch(error => {
                // Log error to Azure Log Analytics or Alert GIBS
                console.error(JSON.stringify(error, null, 2))
                mediaTypesData = []
            });
        mediaReferences.value = mediaTypesData;
        //map venue lookup
        mediaLookup.value = mediaReferences.value.map((venueTypeQuery) => {
            let mediaType: IMediaType = {
                id: -1,
                text: '',
                icon: ''
            };

            mediaType.id = venueTypeQuery.Id;
            mediaType.text = venueTypeQuery.Name;

            if (venueTypeQuery.Id === app.$EnumVenueType.Campus) {
                mediaType.icon = 'venue/gibs-venue-campus';
            }
            else if (venueTypeQuery.Id === app.$EnumVenueType.Hybrid) {
                mediaType.icon = 'venue/gibs-venue-hybrid';
            }
            else if (venueTypeQuery.Id === app.$EnumVenueType.Online) {
                mediaType.icon = 'venue/gibs-venue-online';
            }
            return mediaType;
        });
        return mediaLookup.value;
    }

    async function fetchEventsCourseReferences() { 
        let eventsCoursesTypesData = [] as z.infer<typeof EventsCoursesTypesQuery>
        await fetchJsonData('reference/conference-events-forums-coursetype')
            .then(eventsCoursesTypesQueryJson => { eventsCoursesTypesData = parseJSON(EventsCoursesTypesQuery, eventsCoursesTypesQueryJson) })
            .catch(error => {
                // Log error to Azure Log Analytics or Alert GIBS
                console.error(JSON.stringify(error, null, 2))
                eventsCoursesTypesData = []
            });
            eventsConferencesFilterReferences.value = eventsCoursesTypesData; 
            eventsCourseTypeLookup.value = eventsConferencesFilterReferences.value.map((eventsCourseTypeQuery) => {
            let eventsCourseType: IEventsCoursesType = {
                id: -1,
                text: '',
                icon: ''
            };

            eventsCourseType.id = eventsCourseTypeQuery.Id;
            eventsCourseType.text = eventsCourseTypeQuery.CourseType;

            if (eventsCourseTypeQuery.Id === app.$EnumEventsCoursesType.Conference) {
                eventsCourseType.icon = 'event-group/gibs-eg-conferences';
            }
            else if (eventsCourseTypeQuery.Id === app.$EnumEventsCoursesType.Events) {
                eventsCourseType.icon = 'event-group/gibs-eg-events';
            }
            else if (eventsCourseTypeQuery.Id === app.$EnumEventsCoursesType.Forums) {
                eventsCourseType.icon = 'event-group/gibs-eg-forums';
            }
            eventsArr.value.push(eventsCourseTypeQuery.CourseType);
            return eventsCourseType;
        }); 
        return eventsCourseTypeLookup.value;
    }

    async function fetchFacultyDivisionReferences() { 
        let facultyDivisionTypesData = [] as z.infer<typeof FacultyDivisionQuery>
        await fetchJsonData('reference/facultydivisions')
            .then(divisionTypesQueryJson => { facultyDivisionTypesData = parseJSON(FacultyDivisionQuery, divisionTypesQueryJson) })
            .catch(error => {
                // Log error to Azure Log Analytics or Alert GIBS
                console.error(JSON.stringify(error, null, 2))
                facultyDivisionTypesData = []
            });
            facultyDivisionFilterReferences.value = facultyDivisionTypesData; 
            facultyDivisionLookup.value = facultyDivisionFilterReferences.value.map((facultyDivisionQuery) => {
            let facultyDiv: IFacultyDivision = {
                neme: '',
                code: '' 
            };

            facultyDiv.neme = facultyDivisionQuery.Neme;
            facultyDiv.code = facultyDivisionQuery.Code; 
      //      //console.log("division", facultyDiv);
            return facultyDiv; 
        }); 
        return facultyDivisionLookup.value;
    }

    function fetchEventTypeFilterOptions (){ 
        return eventsArr;
    }

    function fetchProgrammeCourseTypeFilterOptions (){ 
        return programmeCourseArr;
    }

    function mapProgrammeApplicationStepIcons(){
        const applicationSteps = new Array<IProgrammeApplicationStep>();
        const iconFolder = 'application-info-steps'
        let step: IProgrammeApplicationStep = {
            id: 'Supporting documents',
            icon: `${iconFolder}/gibs-supporting-documents`
        };
        applicationSteps.push(step);

        step = {
            id: 'Submit online application',
            icon: `${iconFolder}/gibs-submit-online-application`
        };
        applicationSteps.push(step);

        step = {
            id: 'Forward link',
            icon: `${iconFolder}/gibs-forward-link`
        };
        applicationSteps.push(step);

        step = {
            id: 'Test assigned',
            icon:  `${iconFolder}/gibs-test-assigned`
        };
        applicationSteps.push(step);

        step = {
            id: 'Assess test',
            icon: `${iconFolder}/gibs-assess-test`
        };
        applicationSteps.push(step);
        step = {
            id: 'Await results',
            icon: `${iconFolder}/gibs-await-results`
        };
        applicationSteps.push(step);
        step = {
            id: 'Feedback',
            icon: `${iconFolder}/gibs-feedback`
        };
        applicationSteps.push(step);
        step = {
            id: 'Payment',
            icon: `${iconFolder}/gibs-payment`
        };
        applicationSteps.push(step);
        step = {
            id: 'Registration to follow',
            icon: `${iconFolder}/gibs-registration-to-follow`
        };
        applicationSteps.push(step);
        step = {
            id: 'Entrance test',
            icon: `${iconFolder}/gibs-entrance-test`
        };
        applicationSteps.push(step);
        step = {
            id: 'Online test',
            icon: `${iconFolder}/gibs-online-test`
        };
        applicationSteps.push(step);
        step = {
            id: 'Application processing',
            icon: `${iconFolder}/gibs-application-processing`
        };
        applicationSteps.push(step);
        step = {
            id: 'Communication',
            icon: `${iconFolder}/gibs-communication`
        };
        applicationSteps.push(step);
        step = {
            id: 'Reference number',
            icon: `${iconFolder}/gibs-reference-number`
        };
        applicationSteps.push(step);
        step = {
            id: 'Invitation to test',
            icon: `${iconFolder}/gibs-invitation-to-test`
        };
        applicationSteps.push(step);
        step = {
            id: 'Process & verify',
            icon: `${iconFolder}/gibs-processing-&-verify`
        };
        applicationSteps.push(step);
        step = {
            id: 'Invoiced',
            icon: `${iconFolder}/gibs-invoiced`
        };
        applicationSteps.push(step);
        step = {
            id: 'Proof of payment',
            icon: `${iconFolder}/gibs-proof-fo-payment`
        };
        applicationSteps.push(step);
        step = {
            id: 'Letter of acceptance',
            icon: `${iconFolder}/gibs-letter-of-acceptance`
        };
        step = {
            id: 'Other',
            icon: `${iconFolder}/gibs-other`
        };
        applicationSteps.push(step);

        applicationStepLookup.value = applicationSteps;
    }
    
    function mapProgrammeApplicationStepDirectionColours() {
        const stepDirectionColourPalette = new Array<IProgrammeApplicationDirection>();
        let stepDirection: IProgrammeApplicationDirection = {
            id: 0,
            colour: "text-androidgreen" 
        };
        stepDirectionColourPalette.push(stepDirection);
        stepDirection = {
            id: 1,
            colour: "text-honeyflower" 
        };
        stepDirectionColourPalette.push(stepDirection);
        stepDirection = {
            id:2,
            colour: "text-ruddybrown" 
        };
        stepDirectionColourPalette.push(stepDirection);
        stepDirection = {
            id: 3,
            colour: "text-mughalgreen" 
        };
        stepDirectionColourPalette.push(stepDirection);
        stepDirection = {
            id: 4,
            colour: "text-marigold" 
        };
        stepDirectionColourPalette.push(stepDirection);
        stepDirection = {
            id: 5,
            colour: "text-pucered" 
        };
        stepDirectionColourPalette.push(stepDirection);
        stepDirection = {
            id: 6,
            colour: "text-skobeloff" 
        };
        stepDirectionColourPalette.push(stepDirection);
        stepDirection = {
            id: 7,
            colour: "text-bostonred" 
        };
        stepDirectionColourPalette.push(stepDirection);
        stepDirection = {
            id: 8,
            colour: "text-steelteal" 
        };
        stepDirectionColourPalette.push(stepDirection);
        applicationStepDirectionColourLookup.value = stepDirectionColourPalette;
    }

    function mapCourseClassificationLookup() {
        courseClassificationLookup.value = courseReferences.value.map((courseClassificationQuery) => {
            let courseClassificationLookup: ICourseClassification = {
                id: courseClassificationQuery.Id,
                text: '',
                background: '',
                border: '',
                icon: '' 
            };
            
            if (courseClassificationQuery.Id === app.$EnumCourseType.ExecutiveCourses) {
                courseClassificationLookup.text = 'text-honeyflower';
                courseClassificationLookup.background = 'bg-honeyflower';
                courseClassificationLookup.border = 'border-l-honeyflower';
                courseClassificationLookup.icon = 'programme-group/gibs-pg-executive-courses'
            }
            else if (courseClassificationQuery.Id === app.$EnumCourseType.Academic) {
                courseClassificationLookup.text = 'text-rubyred';
                courseClassificationLookup.background = 'bg-rubyred';
                courseClassificationLookup.border = 'border-l-rubyred';
                courseClassificationLookup.icon = 'programme-group/gibs-pg-academic'
            }
            else if (courseClassificationQuery.Id === app.$EnumCourseType.ShortCourses) {
                courseClassificationLookup.text = 'text-skobeloff';
                courseClassificationLookup.background = 'bg-skobeloff';
                courseClassificationLookup.border = 'border-l-skobeloff';
                courseClassificationLookup.icon= 'programme-group/gibs-pg-short-course';
            }
            else if (courseClassificationQuery.Id === app.$EnumCourseType.Coaching) {
                courseClassificationLookup.text = 'text-pucered';
                courseClassificationLookup.background = 'bg-pucered';
                courseClassificationLookup.border = 'border-l-pucered';
                courseClassificationLookup.icon= 'programme-group/gibs-pg-coaching';
            }
            else if (courseClassificationQuery.Id === app.$EnumCourseType.Masterclasses) {
                courseClassificationLookup.text = 'text-androidgreen';
                courseClassificationLookup.background = 'bg-androidgreen';
                courseClassificationLookup.border = 'border-l-androidgreen';
                courseClassificationLookup.icon = 'programme-group/gibs-pg-master-class';
            }
            else if (courseClassificationQuery.Id === app.$EnumCourseType.Event) {
                courseClassificationLookup.text = 'text-mughalgreen';
                courseClassificationLookup.background = 'bg-mughalgreen';
                courseClassificationLookup.border = 'border-l-mughalgreen';
                courseClassificationLookup.icon = 'event-group/gibs-eg-events';
            }
            else if (courseClassificationQuery.Id === app.$EnumCourseType.Conference) {
                courseClassificationLookup.text = 'text-mughalgreen';
                courseClassificationLookup.background = 'bg-mughalgreen';
                courseClassificationLookup.border = 'border-l-mughalgreen';
                courseClassificationLookup.icon = 'event-group/gibs-eg-conferences';
            }
            else if (courseClassificationQuery.Id === app.$EnumCourseType.Forum) {
                courseClassificationLookup.text = 'text-mughalgreen';
                courseClassificationLookup.background = 'bg-mughalgreen';
                courseClassificationLookup.border = 'border-l-mughalgreen';
                courseClassificationLookup.icon = 'event-group/gibs-eg-forums';
            }
            else if (courseClassificationQuery.Id === app.$EnumCourseType.CustomCourses) {
                courseClassificationLookup.text = 'text-skobeloff';
                courseClassificationLookup.background = 'bg-skobeloff';
                courseClassificationLookup.border = 'border-l-skobeloff';
                courseClassificationLookup.icon = 'programme-group/gibs-pg-custom';
            }
            else if (courseClassificationQuery.Id === app.$EnumCourseType.EntrepreneurshipCourses) {
                courseClassificationLookup.text = 'text-ruddybrown';
                courseClassificationLookup.background = 'bg-ruddybrown';
                courseClassificationLookup.border = 'border-l-ruddybrown';
                courseClassificationLookup.icon = 'programme-group/gibs-pg-entrepreneuship';
            } 
            else if (courseClassificationQuery.Id === app.$EnumCourseType.InHouse) {
                courseClassificationLookup.text = 'text-skobeloff';
                courseClassificationLookup.background = 'bg-skobeloff';
                courseClassificationLookup.border = 'border-l-skobeloff';
                courseClassificationLookup.icon = 'programme-group/gibs-pg-inhouse';
            }
            else if (courseClassificationQuery.Id === app.$EnumCourseType.News) {
                courseClassificationLookup.text = 'text-catalinablue';
                courseClassificationLookup.background = 'bg-catalinablue';
                courseClassificationLookup.border = 'border-l-catalinablue';
                courseClassificationLookup.icon = 'newsicon'
            }
            // else {
            //     courseClassificationLookup.text = 'text-steelblue';
            //     courseClassificationLookup.background = 'bg-steelblue';
            //     courseClassificationLookup.border = 'border-l-steelblue';
            //     courseClassificationLookup.icon = 'venue/gibs-venue-unknown';
            // }
            programmeCourseArr.value.push(courseClassificationQuery.CourseType);
            return courseClassificationLookup;
        });
    } 

    function mapInterestAreaLookup() {
        interestAreaLookup.value = interestAreaReferences.value.map((interestAreaQuery) => {
            let interestAreaLookup: IInterestArea = {
                id: interestAreaQuery.Id,
                text: '',
                background: '',
                border: '',
                icon: '',
                name: ''
            };

            if (interestAreaQuery.Id === app.$EnumInterestArea.CoachingFacilitationMentorship) {
                interestAreaLookup.text = 'text-pucered';
                interestAreaLookup.background = 'bg-pucered';
                interestAreaLookup.border = 'border-l-pucered';
                interestAreaLookup.icon = 'interest-area/gibs-ia-coaching-facilitation-mentorship';
            }
            else if (interestAreaQuery.Id === app.$EnumInterestArea.DigitisationInnovation) {
                interestAreaLookup.text = 'text-skobeloff';
                interestAreaLookup.background = 'bg-skobeloff';
                interestAreaLookup.border = 'border-l-skobeloff';
                interestAreaLookup.icon = 'interest-area/gibs-ia-digitisation-innovation';
            }
            else if (interestAreaQuery.Id === app.$EnumInterestArea.Entrepreneurship) {
                interestAreaLookup.text = 'text-ruddybrown';
                interestAreaLookup.background = 'bg-ruddybrown';
                interestAreaLookup.border = 'border-l-ruddybrown';
                interestAreaLookup.icon = 'interest-area/gibs-ia-entrepreneuship';
            }
            else if (interestAreaQuery.Id === app.$EnumInterestArea.ExecutiveDevelopment) {
                interestAreaLookup.text = 'text-steelteal';
                interestAreaLookup.background = 'bg-steelteal';
                interestAreaLookup.border = 'border-l-steelteal';
                interestAreaLookup.icon = 'interest-area/gibs-ia-executive-development';
            }
            else if (interestAreaQuery.Id === app.$EnumInterestArea.Finance) {
                interestAreaLookup.text = 'text-mughalgreen';
                interestAreaLookup.background = 'bg-mughalgreen';
                interestAreaLookup.border = 'border-l-mughalgreen';
                interestAreaLookup.icon = 'interest-area/gibs-ia-finance';
            }
            else if (interestAreaQuery.Id === app.$EnumInterestArea.ManagementLeadershipExcellence) {
                interestAreaLookup.text = 'text-androidgreen';
                interestAreaLookup.background = 'bg-androidgreen';
                interestAreaLookup.border = 'border-l-androidgreen';
                interestAreaLookup.icon = 'interest-area/gibs-ia-management-leadership-excellence';
            }
            else if (interestAreaQuery.Id === app.$EnumInterestArea.MarketingSales) {
                interestAreaLookup.text = 'text-marigold';
                interestAreaLookup.background = 'bg-marigold';
                interestAreaLookup.border = 'border-l-marigold';
                interestAreaLookup.icon = 'interest-area/gibs-ia-marketing-sales';
            }
            else if (interestAreaQuery.Id === app.$EnumInterestArea.General) {
                interestAreaLookup.text = 'text-catalinablue';
                interestAreaLookup.background = 'bg-catalinablue';
                interestAreaLookup.border = 'border-l-catalinablue';
                interestAreaLookup.icon = 'interest-area/gibs-ia-other';
            }
            else if (interestAreaQuery.Id === app.$EnumInterestArea.PersonalOrganisationalEffectiveness) {
                interestAreaLookup.text = 'text-honeyflower';
                interestAreaLookup.background = 'bg-honeyflower';
                interestAreaLookup.border = 'border-l-honeyflower';
                interestAreaLookup.icon = 'interest-area/gibs-ia-personal-development';
            }
            else if (interestAreaQuery.Id === app.$EnumInterestArea.Strategy) {
                interestAreaLookup.text = 'text-rubyred';
                interestAreaLookup.background = 'bg-rubyred';
                interestAreaLookup.border = 'border-l-rubyred';
                interestAreaLookup.icon = 'interest-area/gibs-ia-strategy';
            }
            return interestAreaLookup;
        });
        return interestAreaLookup.value;
    }

    function mapVenueLookup() {
        venueLookup.value = venueReferences.value.map((venueTypeQuery) => {
            let venueType: IVenueType = {
                id: -1,
                text: '',
                icon: ''
            };

            venueType.id = venueTypeQuery.Id;
            venueType.text = venueTypeQuery.VenueName;

            if (venueTypeQuery.Id === app.$EnumVenueType.Campus) {
                venueType.icon = 'venue/gibs-venue-campus';
            }
            else if (venueTypeQuery.Id === app.$EnumVenueType.Hybrid) {
                venueType.icon = 'venue/gibs-venue-hybrid';
            }
            else if (venueTypeQuery.Id === app.$EnumVenueType.Online) {
                venueType.icon = 'venue/gibs-venue-online';
            } else {
                venueType.icon = 'venue/gibs-venue-unknown';
            }
            return venueType;
        });
        return venueLookup.value;
    }
    
    function findCourseClassifcationReference (courseClassificationId: string): ICourseClassification {
        let courseClassification: ICourseClassification | undefined;
        courseClassification = courseClassificationLookup.value.find((courseReference) => {
            return courseReference.id === parseInt(courseClassificationId);
        });
        
        if(typeof(courseClassification) === 'undefined') {
            courseClassification = {
                id: -1,
                text: 'text-steelblue',
                background: 'bg-steelblue',
                border: 'border-l-steelblue',
                icon: 'venue/gibs-venue-unknown'
            }
        }
        return courseClassification;
    };
    
    function findContentHubReference (contentHubId: string): IContentHubInterestArea {
        let contentHubClassification: IContentHubInterestArea | undefined;
        contentHubClassification = contentHubInterestAreaLookup.value.find((newsReference) => {
            return newsReference.id === parseInt(contentHubId);
        });
        
        if(typeof(contentHubClassification) === 'undefined') {
            contentHubClassification = {
                id: -1,
                text: 'text-steelblue',
                background: 'bg-steelblue',
                border: 'border-l-steelblue',
                icon: 'venue/gibs-venue-unknown',
                name: ''
            }
        }
        return contentHubClassification;
    };

    function findVenueTypeReference (venueTypeId: string): IVenueType {
        let venueType: IVenueType | undefined;
        venueType = venueLookup.value.find((venueReference) => {
            return venueReference.text === venueTypeId;
        });
        
        if(typeof(venueType) === 'undefined') {
            venueType = {
                id: -1,
                text: 'text-steelblue',
                icon: 'venue/gibs-venue-unknown'
            }
        }
        return venueType;
    };

    async function findNewsIntemrestAreaReference(interestAreaId: number): Promise<IInterestArea> {
        try {
            let interestAreaReferenceData = await fetchJsonData('reference/interestareas');
            interestAreaReferences.value = parseJSON(InterestAreaQuery, interestAreaReferenceData);
            
            let interestAreaLookupMap: { [key: number]: IInterestArea } = {};
            let lookup;
            
            // Define mappings for interest areas
            lookup = interestAreaReferences.value.map(interestAreaQuery => {
                const interestAreaType: IInterestArea = {
                    id: interestAreaQuery.Id,
                    text: '',
                    background: '',
                    border: '',
                    icon: '',
                    name: interestAreaQuery.InterestAreaName
                };
    
                switch (interestAreaQuery.Id) {
                    case app.$EnumInterestArea.CoachingFacilitationMentorship:
                        interestAreaType.text = 'text-pucered';
                        interestAreaType.background = 'bg-pucered';
                        interestAreaType.border = 'border-l-pucered';
                        interestAreaType.icon = 'interest-area/gibs-ia-coaching-facilitation-mentorship';
                        break;
                    // Add cases for other interest areas...
    
                    default:
                        interestAreaType.text = 'text-steelblue';
                        interestAreaType.background = 'bg-steelblue';
                        interestAreaType.border = 'border-l-steelblue';
                        interestAreaType.icon = 'venue/gibs-venue-unknown'; 
                        break;
                }
    
                interestAreaLookupMap[interestAreaType.id] = interestAreaType;
                return interestAreaType;
            });
    
            // Find interest area from the map
            const interestArea = interestAreaLookupMap[interestAreaId];
    
            if (interestArea) {
                return interestArea;
            } else {
                return {
                    id: -1,
                    text: 'text-steelblue',
                    background: 'bg-steelblue',
                    border: 'border-l-steelblue',
                    icon: 'venue/gibs-venue-unknown',
                    name: 'Unknown'
                };
            }
        } catch (error) {
            console.error('Error fetching interest areas:', error);
            return {
                id: -1,
                text: 'text-steelblue',
                background: 'bg-steelblue',
                border: 'border-l-steelblue',
                icon: 'venue/gibs-venue-unknown',
                name: 'Unknown'
            };
        }
    }
    
    
    async function findNewsInterestAreaReference (): Promise<IInterestArea[]> {
        let interestAreaReferenceData = [] as z.infer<typeof InterestAreaQuery>
        await fetchJsonData('reference/interestareas')
            .then(interestAreaQueryJson => { interestAreaReferenceData = parseJSON(InterestAreaQuery, interestAreaQueryJson) })
            .catch(error => {
                // Log error to Azure Log Analytics or Alert GIBS
                console.error(JSON.stringify(error, null, 2))
                interestAreaReferenceData = []
            });
        interestAreaReferences.value = interestAreaReferenceData; 
        const lookup = interestAreaReferences.value.map((interestAreaQuery) => { 
            let interestAreaType: IInterestArea = {
                id: interestAreaQuery.Id,
                text: '',
                background: '',
                border: '',
                icon: '',
                name: ''
            };

            interestAreaType.id = interestAreaQuery.Id;
            interestAreaType.name = interestAreaQuery.InterestAreaName; 

            if (interestAreaQuery.Id === app.$EnumInterestArea.CoachingFacilitationMentorship) {
                interestAreaType.text = 'text-pucered';
                interestAreaType.background = 'bg-pucered';
                interestAreaType.border = 'border-l-pucered';
                interestAreaType.icon = 'interest-area/gibs-ia-coaching-facilitation-mentorship';
            }
            else if (interestAreaQuery.Id === app.$EnumInterestArea.DigitisationInnovation) {
                interestAreaType.text = 'text-skobeloff';
                interestAreaType.background = 'bg-skobeloff';
                interestAreaType.border = 'border-l-skobeloff';
                interestAreaType.icon = 'interest-area/gibs-ia-digitisation-innovation';
            }
            else if (interestAreaQuery.Id === app.$EnumInterestArea.Entrepreneurship) {
                interestAreaType.text = 'text-ruddybrown';
                interestAreaType.background = 'bg-ruddybrown';
                interestAreaType.border = 'border-l-ruddybrown';
                interestAreaType.icon = 'interest-area/gibs-ia-entrepreneuship';
            }
            else if (interestAreaQuery.Id === app.$EnumInterestArea.ExecutiveDevelopment) {
                interestAreaType.text = 'text-steelteal';
                interestAreaType.background = 'bg-steelteal';
                interestAreaType.border = 'border-l-steelteal';
                interestAreaType.icon = 'interest-area/gibs-ia-executive-development';
            }
            else if (interestAreaQuery.Id === app.$EnumInterestArea.Finance) {
                interestAreaType.text = 'text-mughalgreen';
                interestAreaType.background = 'bg-mughalgreen';
                interestAreaType.border = 'border-l-mughalgreen';
                interestAreaType.icon = 'interest-area/gibs-ia-finance';
            }
            else if (interestAreaQuery.Id === app.$EnumInterestArea.ManagementLeadershipExcellence) {
                interestAreaType.text = 'text-androidgreen';
                interestAreaType.background = 'bg-androidgreen';
                interestAreaType.border = 'border-l-androidgreen';
                interestAreaType.icon = 'interest-area/gibs-ia-management-leadership-excellence';
            }
            else if (interestAreaQuery.Id === app.$EnumInterestArea.MarketingSales) {
                interestAreaType.text = 'text-marigold';
                interestAreaType.background = 'bg-marigold';
                interestAreaType.border = 'border-l-marigold';
                interestAreaType.icon = 'interest-area/gibs-ia-marketing-sales';
            }
            else if (interestAreaQuery.Id === app.$EnumInterestArea.General) {
                interestAreaType.text = 'text-catalinablue';
                interestAreaType.background = 'bg-catalinablue';
                interestAreaType.border = 'border-l-catalinablue';
                interestAreaType.icon = 'interest-area/gibs-ia-other';
            }
            else if (interestAreaQuery.Id === app.$EnumInterestArea.PersonalOrganisationalEffectiveness) {
                interestAreaType.text = 'text-honeyflower';
                interestAreaType.background = 'bg-honeyflower';
                interestAreaType.border = 'border-l-honeyflower';
                interestAreaType.icon = 'interest-area/gibs-ia-personal-development';
            }
            else if (interestAreaQuery.Id === app.$EnumInterestArea.Strategy) {
                interestAreaType.text = 'text-rubyred';
                interestAreaType.background = 'bg-rubyred';
                interestAreaType.border = 'border-l-rubyred';
                interestAreaType.icon = 'interest-area/gibs-ia-strategy';
            }
            return interestAreaType;
        });
        return lookup;
        // return interestAreaLookup.value;
        // let interestArea: IInterestArea | undefined;
        // interestArea = lookup.find((courseReference) => {
        //     return courseReference.id === interestAreaId;
        // });
        
        // if(typeof(interestArea) === 'undefined') {
        //     interestArea = {
        //         id: -1,
        //         text: 'text-steelblue',
        //         background: 'bg-steelblue',
        //         border: 'border-l-steelblue',
        //         icon: 'venue/gibs-venue-unknown',
        //         name: 'Unknown'
        //     }
        // }
        // return interestArea;
    //     const foundInterestArea = lookup.find(area => area.id === interestAreaId);

    // return foundInterestArea;
    };

    function findInterestAreaReference (interestAreaId: number): IInterestArea {
        let interestArea: IInterestArea | undefined;
        interestArea = interestAreaLookup.value.find((courseReference) => {
            return courseReference.id === interestAreaId;
        });
        
        if(typeof(interestArea) === 'undefined') {
            interestArea = {
                id: -1,
                text: 'text-steelblue',
                background: 'bg-steelblue',
                border: 'border-l-steelblue',
                icon: 'venue/gibs-venue-unknown',
                name: 'Unknown'
            }
        }
        return interestArea;
    };

    function setTabsAccentColours(tabValue: string)
    {  
        let tabAccentLookup: ITabAccents = {  
            borderLeft: '',
            background: ''
        };

        if (tabValue == "Fees") { 
            tabAccentLookup.borderLeft = 'border-l-androidgreen'; 
            tabAccentLookup.background = 'bg-androidgreen';
        }
        if (tabValue == "MinimumRequirements") { 
            tabAccentLookup.borderLeft = 'border-l-ruddybrown'; 
            tabAccentLookup.background = 'bg-ruddybrown';
        }
        if (tabValue =="MBAChecklist" || tabValue =="Topics") { 
            tabAccentLookup.borderLeft = 'border-l-catalinablue'; 
            tabAccentLookup.background = 'bg-catalinablue';
        }
        if (tabValue == "ApplicationDates") { 
            tabAccentLookup.borderLeft = 'border-l-rubyred'; 
            tabAccentLookup.background = 'bg-rubyred';
        }
        if (tabValue == "InformationSession") { 
            tabAccentLookup.borderLeft = 'border-l-skobeloff'; 
            tabAccentLookup.background = 'bg-skobeloff';
        }
        return tabAccentLookup;
    }
    
    function findProgrammeApplicationStepIcon(id: string): IProgrammeApplicationStep {
        let applicationStep: IProgrammeApplicationStep | undefined;
        applicationStep = applicationStepLookup.value.find((step) => {
            return step.id === id;
        });
        
        if(typeof(applicationStep) === 'undefined') {
            applicationStep = {
                id:  '',
                icon: 'venue/gibs-venue-unknown'
            };
        }
        return applicationStep;
    }

    function findProgrammeApplicationStepDirectionColour(index) {
        let applicationStepDirection: IProgrammeApplicationDirection | undefined;

        const colourIndex = index % applicationStepDirectionColourLookup.value.length;
        applicationStepDirection = applicationStepDirectionColourLookup.value[colourIndex];
        
        if(typeof(applicationStepDirection) === 'undefined') {
            applicationStepDirection = {
                id:  -1,
                colour: ''
            };
        }
        return applicationStepDirection;
    }
    
    function getCourseClassificationIdByText(courseTypeText: String): null|Number {
        let result: null| Number = null;
        const courseClassification = courseReferences.value.find((courseClassificationQuery) => {
            return courseClassificationQuery.CourseType == courseTypeText;
        });

        if (typeof(courseClassification) !== 'undefined') {
            result = courseClassification.Id;
        }
        return result;
    }

    /** 
     * Initialise some of the statically mapped functions
    */
    mapProgrammeApplicationStepIcons();
    mapProgrammeApplicationStepDirectionColours();

    return {
        fetchAllReferences,
        courseReferences,
        getCourseReferences,
        fetchCourseReferences,
        findCourseClassifcationReference,
        findContentHubReference,
        fetchVenueReferences,
        findVenueTypeReference,
        fetchInterestAreaReferences,
        fetchCourseInterestAreaReferences,
        fetchEventTypeFilterOptions,
        fetchProgrammeCourseTypeFilterOptions,
        findInterestAreaReference,
        setTabsAccentColours,
        findProgrammeApplicationStepIcon,
        findProgrammeApplicationStepDirectionColour,
        fetchEventsCourseReferences,
        fetchFacultyDivisionReferences,
        findNewsInterestAreaReference,
        mapInterestAreaLookup,
        fetchMediaReferences,
        fetchContentHubInterestAreaReferences,
        fetchCourseDurationReferences,
        fetchCourseFinderCostReferences,
        getCourseClassificationIdByText
    }
});